import {
  useForm,
  getFormProps,
  getInputProps,
  getSelectProps,
} from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import {
  redirect,
  useFetcher,
  type ClientActionFunctionArgs,
} from '@remix-run/react';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { toast } from 'sonner';
import { z } from 'zod';
import { useMediaQuery } from '#app/hooks/use-media-query';
import { useIsPending } from '#app/utils/misc';
import { templates } from '#app/utils/sandpack';
import { ErrorList, Field, SelectField } from './forms';
import { type NewProjectDialogAction } from './new-project-dialog.server';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './ui/drawer';
import { StatusButton } from './ui/status-button';

export type NewProjectDialogProps = {
  trigger: React.ReactNode;
};

export const NewProjectFormSchema = z.object({
  name: z.string(),
  template: z.enum(templates),
});

export async function clientAction({ serverAction }: ClientActionFunctionArgs) {
  const response = await serverAction<NewProjectDialogAction>();
  if (response.type === 'server') return response;

  try {
    localStorage.setItem(response.project.id, JSON.stringify(response.project));
  } catch (error) {
    toast.error('Failed to create local project');
    return null;
  }

  throw redirect(`/projects/${response.project.id}/edit`);
}

export function NewProjectDialog({ trigger }: NewProjectDialogProps) {
  const fetcher = useFetcher<typeof clientAction>();
  const lastData = fetcher.data;
  const isPending = useIsPending();

  const [form, fields] = useForm({
    id: 'new-project-form',
    constraint: getZodConstraint(NewProjectFormSchema),
    lastResult: lastData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: NewProjectFormSchema });
    },
    shouldRevalidate: 'onBlur',
  });

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const templateSelectProps = getSelectProps(fields.template);

  const formComponent = (
    <fetcher.Form method="POST" action="/projects/new" {...getFormProps(form)}>
      <HoneypotInputs />

      <div className="flex flex-col gap-2">
        <Field
          labelProps={{ children: 'Name' }}
          inputProps={{
            ...getInputProps(fields.name, { type: 'text' }),
            autoFocus: true,
          }}
          errors={fields.name.errors}
        />

        <SelectField
          labelProps={{ children: 'Template' }}
          selectProps={{
            root: {
              ...templateSelectProps,
              defaultValue: templateSelectProps.defaultValue as string,
            },
            options: [...templates],
          }}
          errors={fields.template.errors}
        />

        <ErrorList errors={form.errors} id={form.errorId} />
      </div>
    </fetcher.Form>
  );

  return isDesktop ? (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Configure Project</DialogTitle>
        </DialogHeader>

        {formComponent}

        <DialogFooter>
          <StatusButton
            form={form.id}
            status={isPending ? 'pending' : form.status ?? 'idle'}
            type="submit"
            disabled={isPending}
          >
            Create Project
          </StatusButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className="px-4">
        <DrawerHeader>
          <DrawerTitle>Configure Project</DrawerTitle>
        </DrawerHeader>

        {formComponent}

        <DrawerFooter>
          <StatusButton
            form={form.id}
            status={isPending ? 'pending' : form.status ?? 'idle'}
            type="submit"
            disabled={isPending}
          >
            Create Project
          </StatusButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
